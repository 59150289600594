
/**
 * Single decvice information
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import {Device} from "@/interface/DeviceInterface";
import {DeviceProjectDetails} from "@/stores/modules/inventoryDevices";
import {Component, Vue} from "vue-property-decorator";
import {mapGetters, mapActions, ActionMethod, mapMutations, MutationMethod} from "vuex";

@Component({
  name: "InventoryItem",
  computed: {
    ...mapGetters(["getDeviceById", "isUserAdmin", "getCurrentDeviceProjectsDetails"])
  },
  methods: {
    ...mapMutations(["setCurrentDeviceProjectsDetails"]),
    ...mapActions(["GetDeviceProjectsDetails"])
  }
})
export default class InventoryItem extends Vue {
  /*----------  Vuex  ----------*/
  private getDeviceById!: Function;

  private GetDeviceProjectsDetails!: ActionMethod;
  setCurrentDeviceProjectsDetails!: MutationMethod;

  private readonly isUserAdmin!: boolean;
  readonly getCurrentDeviceProjectsDetails!: DeviceProjectDetails[];
  /*----------  Local data  ----------*/
  device: null | Device = null;

  /*----------  vue life cycles  ----------*/
  created() {
    this.getCurrentId();
  }

  /*----------  Methods  ----------*/

  getCurrentId() {
    if (this.getDeviceById(this.$route.params.id) === undefined) {
      this.$router.push({path: "/inventory"});
    } else {
      this.setCurrentDeviceProjectsDetails([]);
      this.device = this.getDeviceById(this.$route.params.id);
      this.device?.type.name === "main controller" && this.device?.projects.length > 0
        ? this.GetDeviceProjectsDetails(this.device?.serialNumber)
        : null;
    }
  }
}
